import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import Modal from 'react-modal';

Modal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
